import * as React from "react";
import styled from "styled-components";
import { Loader, Checkbox } from "semantic-ui-react";

import { useActiveRating } from "./../../../context/activeRating";
import { useVineRatingMapping } from "./../../../context/vineRatingMapping";
import RatingFormButtons from "../../../components/RatingFormButtons";

import FieldMap from "./FieldMap";

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  overflow: hidden;
`;

const FormWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
`;

const MapWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: #e6e4e0;
`;

export default function RatingFormAndMapPolygons() {
  const { polygonRatingMapping, setPolygonRatingMapping } =
    useVineRatingMapping();
  const {
    activeRating,
    activeTrait,
    setActiveTrait,
    setActivePolygon,
    loading,
    cycleThroughTraits,
    reversePolygonOrder,
    setReversePolygonOrder,
  } = useActiveRating();

  function handleAfterRated(_vineNumber, vineRating, jumpToNext) {
    console.log("handleAfterRated");
    if (jumpToNext) {
      if (cycleThroughTraits) {
        const allTraits = activeRating.rating_rating_by_pk.rating_rating_traits;
        const activeTraitIndex = allTraits.findIndex(
          (t) => t.id === activeTrait.id
        );
        if (activeTraitIndex < allTraits.length - 1) {
          setActiveTrait(allTraits[activeTraitIndex + 1]);
        } else {
          setActiveTrait(allTraits[0]);
          // find next active polygon
          const polygons =
            activeRating.rating_rating_by_pk.vineyard_vineyard
              .vineyard_polygons;
          const sortedPolygons = polygons.sort(
            (a, b) => Number(a.number) - Number(b.number)
          );
          let nextPolygon;
          if (!reversePolygonOrder) {
            nextPolygon = sortedPolygons.find(
              (p) =>
                Number(p.number) > Number(vineRating.vineyard_polygon.number)
            );
          } else {
            nextPolygon = sortedPolygons
              .reverse()
              .find(
                (p) =>
                  Number(p.number) < Number(vineRating.vineyard_polygon.number)
              );
          }
          if (nextPolygon) {
            setActivePolygon(nextPolygon);
          }
        }
      } else {
        // // find next active polygon
        const polygons =
          activeRating.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons;
        const sortedPolygons = polygons.sort(
          (a, b) => Number(a.number) - Number(b.number)
        );
        let nextPolygon;
        if (!reversePolygonOrder) {
          nextPolygon = sortedPolygons.find(
            (p) => Number(p.number) > Number(vineRating.vineyard_polygon.number)
          );
        } else {
          nextPolygon = sortedPolygons
            .reverse()
            .find(
              (p) =>
                Number(p.number) < Number(vineRating.vineyard_polygon.number)
            );
        }
        if (nextPolygon) {
          setActivePolygon(nextPolygon);
        }
      }
    }

    // update the mapping
    setPolygonRatingMapping({
      ...polygonRatingMapping,
      [`${activeTrait.trait_trait.id}-${vineRating.vineyard_polygon.id}`]:
        vineRating,
    });
  }
  return (
    <Wrapper>
      <FormWrapper>
        {!activeRating || !activeTrait ? (
          <Loader active />
        ) : (
          <RatingFormButtons handleAfterVineRated={handleAfterRated} />
        )}
      </FormWrapper>
      <MapWrapper>
        {loading ? <Loader active /> : <FieldMap />}
        <div
          style={{
            position: "absolute",
            bottom: 0,
            left: 0,
            background: "white",
            zIndex: 100,
            padding: 8,
          }}
        >
          <Checkbox
            label="Go backwards"
            toggle
            checked={reversePolygonOrder}
            onChange={(_event, data) => setReversePolygonOrder(data.checked)}
          />
        </div>
      </MapWrapper>
    </Wrapper>
  );
}
