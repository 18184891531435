import * as React from "react";
import { GeoJSONLayer, Marker } from "react-mapbox-gl";
import { featureCollection } from "@turf/helpers";
import centroid from "@turf/centroid";

import { ACTIVE_COLOR, INACTIVE_COLOR } from "./../../../../constants/colors";
import { useActiveRating } from "../../../../context/activeRating";
import { useVineRatingMapping } from "../../../../context/vineRatingMapping";
import { Icon } from "semantic-ui-react";

export default function PolygonGeojson() {
  const { activeRating, activeTrait, setActivePolygon, activePolygon } =
    useActiveRating();
  const { polygonRatingMapping } = useVineRatingMapping();

  function getColor(someId) {
    const inRating = polygonRatingMapping[someId];
    if (!inRating) {
      return INACTIVE_COLOR;
    } else {
      const color = inRating.trait_traittier?.color;
      if (color) {
        return color;
      } else {
        const justAComment =
          inRating.comment &&
          inRating.numerical_value === null &&
          inRating.trait_traittier === null &&
          inRating.text_value === null;
        if (justAComment) {
          return INACTIVE_COLOR;
        } else {
          return "#16ab39";
        }
      }
    }
  }

  const polygonsGeojson = React.useMemo(() => {
    if (!activeRating) {
      return null;
    }
    const themPolygons =
      activeRating.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons;

    // .filter(
    //   (p) => p.id !== activePolygon.id
    // );
    const polygonCollection = featureCollection(
      themPolygons.map((p) => ({
        type: "Feature",
        geometry: p.geometry,
        properties: {
          id: p.id,
          ...p.properties,
          color: getColor(`${activeTrait.trait_trait.id}-${p.id}`),
          lineWidth: Object.values(p.properties).join(" ").includes("Rand:")
            ? 6
            : 0,
          comment:
            polygonRatingMapping[`${activeTrait.trait_trait.id}-${p.id}`]
              ?.comment,
        },
      }))
    );
    return polygonCollection;
  }, [activeRating, activePolygon, polygonRatingMapping, activeTrait]);

  const activePolygonGeojson = React.useMemo(() => {
    if (!activePolygon) {
      return null;
    }
    return {
      type: "Feature",
      geometry: activePolygon.geometry,
      properties: {
        id: activePolygon.id,
        ...activePolygon.properties,
        color: getColor(`${activeTrait.trait_trait.id}-${activePolygon.id}`),
        lineColor: ACTIVE_COLOR,
      },
    };
  }, [activePolygon, polygonRatingMapping, activeTrait]);

  function handleClick(features = []) {
    if (features.length) {
      const feature = features[0];
      const { properties } = feature;
      const polygon =
        activeRating.rating_rating_by_pk.vineyard_vineyard.vineyard_polygons.find(
          (p) => p.id === properties.id
        );
      if (polygon) {
        setActivePolygon(polygon);
      }
    }
  }

  return (
    <>
      {polygonsGeojson && (
        <GeoJSONLayer
          id="polygons"
          data={polygonsGeojson}
          fillOnClick={(e) => handleClick(e.features)}
          fillPaint={{
            "fill-color": ["get", "color"],
          }}
          linePaint={{
            "line-color": "black",
            "line-width": ["get", "lineWidth"],
            "line-dasharray": [1, 1, 1],
          }}
        />
      )}
      {activePolygonGeojson && (
        <GeoJSONLayer
          data={activePolygonGeojson}
          linePaint={{
            "line-color": ["get", "lineColor"],
            "line-width": 8,
          }}
          fillPaint={{
            "fill-color": "transparent",
          }}
        />
      )}

      {polygonsGeojson &&
        polygonsGeojson.features.map((feature, i) => {
          if (feature.properties.comment) {
            const centr = centroid(feature);
            return (
              <Marker
                key={i}
                coordinates={centr.geometry.coordinates}
                offset={[-6, 8]}
              >
                <div style={{ color: "white" }}>
                  <Icon name="comment outline" color="white" />
                </div>
              </Marker>
            );
          }
          return null;
        })}
    </>
  );
}
